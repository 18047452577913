/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forget.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../IP/pages/forget/forgetIP.component.ngfactory";
import * as i3 from "../../IP/pages/forget/forgetIP.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/router";
import * as i6 from "../../services/user.service";
import * as i7 from "@angular/common";
import * as i8 from "./forget.component";
import * as i9 from "../../services/config.service";
var styles_ForgetComponent = [i0.styles];
var RenderType_ForgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgetComponent, data: {} });
export { RenderType_ForgetComponent as RenderType_ForgetComponent };
function View_ForgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "app-forgetEkampus", [], null, null, null, null, null))], null, null); }
function View_ForgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-forgetIP", [], null, null, null, i2.View_ForgetIPComponent_0, i2.RenderType_ForgetIPComponent)), i1.ɵdid(2, 114688, null, 0, i3.ForgetIPComponent, [i4.Meta, i4.Title, i5.ActivatedRoute, i5.Router, i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_ForgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgetComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgetComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.entityType == "EKAMPUS"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.entityType == "IP"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ForgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forget", [], null, null, null, View_ForgetComponent_0, RenderType_ForgetComponent)), i1.ɵdid(1, 114688, null, 0, i8.ForgetComponent, [i9.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgetComponentNgFactory = i1.ɵccf("app-forget", i8.ForgetComponent, View_ForgetComponent_Host_0, {}, {}, []);
export { ForgetComponentNgFactory as ForgetComponentNgFactory };
